import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../views/HomePage.vue';
import ProductsPage from '../views/ProductsPage.vue';
import NewsPage from '../views/NewsPage.vue';
import TopicPage from '../views/TopicPage.vue';
import NotFoundPage from '../views/NotFoundPage.vue';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/', 
      component: HomePage,
    },
    {
      path: '/products', 
      component: ProductsPage,
    },
    {
      path: '/news', 
      component: NewsPage,
    },
    {
      path: '/topic', 
      component: TopicPage,
    },
    {
      path: '*', 
      component: NotFoundPage,
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
});