<template>
  <div>
    <the-header></the-header>
    <div class="notFoundBg">
      <div>
        <p><img src="../assets/images/not-found-img.png" alt="not found img"></p>
        <router-link to="/" class="moveButton">
          Home
          <img
            src="../assets/images/home/ic-more.png"
            alt="go home Button"
            aria-hidden="true"
          />
        </router-link>
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from '../components/TheFooter.vue'
import TheHeader from '../components/TheHeader.vue'
export default {
  components: { TheHeader, TheFooter },

}
</script>

<style lang='scss' scoped>
.notFoundBg {
  width: 100%;
  height: calc(100vh - 64px);
  background-image: url('../assets/images/not-found-bg-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  div { 
    position: absolute;
    top: 219px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 638px;
    height: 434px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    text-align: center;

    @include respond($t_width) {
      width: 548px;
      height: 434px;
    }

    @include respond($m_width) {
      width: 320px;
      height: 420px;
    }

    p {
      margin: 37px auto 8px;
    }

    .moveButton {
      display: inline-block;
      width: 180px;
      height: 50px;
      line-height: 50px;
      color: $newsContentColor;
      font-weight: 400;
      font-size: 24px;
      background-color: #ffffff;
      border: 1px solid $mainGray;
      border-radius: 30px;

      @include respond($t_width) {
        width: 160px;
        height: 44px;
        line-height: 44px;
        font-size: 20px;
      }

      @include respond($m_width) {
        width: 120px;
        height: 33px;
        line-height: 33px;
        font-size: 16px;
      }

      img {
        display: inline-block;
        margin-left: 20px;

        @include respond($t_width) {
          width: 9.81px;
        }

        @include respond($m_width) {
          width: 8.83px;
        }
      }
    }

  }
}
</style>