import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    lang: 'ko',
  },
  mutations: {
    lang(state, lang) {
      state.lang = lang;
    }
  },
});