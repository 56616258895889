<template>
  <div>
    <div id="footer">
      <div class="center">
        <!-- sesu logo -->
        <p class="sesuFooterLogo">
          <img src="../assets/images/headerfooter/sesuai-footer-logo.png" alt="sesuai-footer-lofo">
        </p>
        <!-- sesu info -->
        <div class="info">
          <address class="add01">{{ $t('footer.info.add01') }}</address>
          <address class="add02">{{ $t('footer.info.add02') }}</address>
          <address class="add03">{{ $t('footer.info.add03') }}</address>
          <small>{{ $t('footer.info.copy') }}</small>
        </div>
        <!-- sesu contact -->
        <div class="contact">
          <p><img src="../assets/images/headerfooter/venture-logo.png" alt="venture"></p>
          <ul>
            <li><a href="https://www.youtube.com/channel/UCR_hcpsBv8L8oMMWmdTWZ3A" target='_blank'><img src="../assets/images/headerfooter/ic-youtube.png" alt="youtube"></a></li>
            <li><a href="https://www.linkedin.com/company/sesu-ai" target='_blank'><img src="../assets/images/headerfooter/ic-linkedin.png" alt="linkedin"></a></li>
            <li><a href="mailto:jazzson@sesu.ai" target='_blank'><img src="../assets/images/headerfooter/ic-email.png" alt="email"></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
#footer {
  background-color: #f8f8f8; 
  padding: 70px 0;
  width: 100%; 

  @include respond($t_width) {
    padding: 10px 0;
  }

  @include respond($m_width) {
    padding: 24px 10px 20px;
  }

  .center {
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;

    @include respond($m_width) {
      flex-flow: column nowrap;
      align-items: center;
    }

    .sesuFooterLogo {
      box-sizing: border-box;

      img {
        display: inline-block;
        margin-top: 39px;
      }

      @include respond($t_width) {
        width: 102px;

        img {
          width: 100%;
          margin-top: 56px;
        }
      }

      @include respond($m_width) {
        img {
          width: 100%;
          margin-top: 0;
          margin-bottom: 23px;
        }
      }
    }

    .info {
      width: 739px;
      margin-top: 22px;
      box-sizing: border-box;

      address, small {
        color: rgba($color: #000000, $alpha: 0.6);
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
      }

      address {
        margin-bottom: 8px;

        &.add03 {
          margin-bottom: 24px;
        }
      }

      small {
        position: relative;

        &::before {
          content: '';
          width:739px;
          height: 1px;
          background-color: rgba($color: #000000, $alpha: 0.6);
          position: absolute;
          left: 0;
          top: -50%;
        }
      }

      @include respond($t_width) {
        width: 334px;
        margin-top: 20px;

        address, small {
          font-size: 11px; 
        }

        small {
          &::before {
            width: 334px;
          }
        }
      }

      @include respond($m_width) {
        order: 1;
        margin-top: 17px;
      }
    }

    .contact {
      width: 215px;
      height: 140px;
      padding: 15px 23px;
      box-sizing: border-box;
      @include borderStyle;

      @include respond($m_width) {
        width: 343px;
        height: 86px;
        padding: 23px 8px;
        display: flex;
        justify-content: space-between;
      }

      p {
        text-align: center;
        margin-bottom: 25px;
      }

      ul {
        display: flex;
        justify-content: space-between;

        @include respond($m_width) {
          width: 168px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>