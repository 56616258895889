import Vue from 'vue';
import App from './App.vue';
import {router} from './router/index.js';
import {store} from './store/index.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n.js';
import {BootstrapVue} from 'bootstrap-vue';
import VueMoment from 'vue-moment'

Vue.use(AOS);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueMoment);

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
  mounted() {
    AOS.init({
    });
  },
  
}).$mount('#app');
