<template>
  <div id="productPage">
    <the-header></the-header>
    <div id="container">
      <h2 class="pageTitle">{{ $t('products.pageTitle') }}</h2>
      <!-- 01. sesu Platform -->
      <section id="sesuPlatform" class="center">
        <h3 class="blind">sesu platform</h3>
        <div class="sectionTitle" data-aos="fade-up" data-aos-duration="1000">
          <h4 class="subTitle">
            {{ $t('products.platform.platformTitle.title') }}
            <img
              src="../assets/images/bee-icon.png"
              alt="bee-icon"
              class="beeIcon"
              aria-hidden="true"
            />
          </h4>
          <p class="titleDescription">
            {{ $t('products.platform.platformTitle.desc1') }}
          </p>
          <p class="titleDescription">
            {{ $t('products.platform.platformTitle.desc2') }}
          </p>
        </div>
        <div class="platformImg" data-aos="fade-up" data-aos-duration="1000">
          <p>
            <img
              src="../assets/images/products/platform-pc.png"
              alt="sesu-platform-title"
            />
          </p>
          <div class="img">
            <img
              src="../assets/images/products/sesu-platform.png"
              alt="sesu-platform"
              id="sesuPlatformImg"
            />
          </div>
        </div>
        <div class="platformTech" data-aos="fade-up" data-aos-duration="1000">
          <ul class="technologyWrap">
            <li class="technologyList recordData">
              <span class="technologyBar" aria-hidden="true"></span>
              <p class="listTitle">
                {{ $t('products.platform.platformTech.recordData.title') }}
              </p>
              <p class="listText listTestFirst">
                {{ $t('products.platform.platformTech.recordData.text1') }}
              </p>
              <p class="listText">
                {{ $t('products.platform.platformTech.recordData.text2') }}
              </p>
              <p class="listText">
                {{ $t('products.platform.platformTech.recordData.text3') }}
              </p>
            </li>
            <li class="technologyList certifyData">
              <span class="technologyBar" aria-hidden="true"></span>
              <p class="listTitle">
                {{ $t('products.platform.platformTech.certifyData.title') }}
              </p>
              <p class="listText listTestFirst">
                {{ $t('products.platform.platformTech.certifyData.text1') }}
              </p>
              <p class="listText">
                {{ $t('products.platform.platformTech.certifyData.text2') }}
              </p>
              <p class="listText">
                {{ $t('products.platform.platformTech.certifyData.text3') }}
              </p>
            </li>
            <li class="technologyList statistict">
              <span class="technologyBar" aria-hidden="true"></span>
              <p class="listTitle">
                {{ $t('products.platform.platformTech.statistict.title') }}
              </p>
              <p class="listText listTestFirst">
                {{ $t('products.platform.platformTech.statistict.text1') }}
              </p>
              <p class="listText">
                {{ $t('products.platform.platformTech.statistict.text2') }}
              </p>
              <p class="listText">
                {{ $t('products.platform.platformTech.statistict.text3') }}
              </p>
            </li>
            <li class="technologyList offeringData">
              <span class="technologyBar" aria-hidden="true"></span>
              <p class="listTitle">
                {{ $t('products.platform.platformTech.offeringData.title') }}
              </p>
              <p class="listText listTestFirst">
                {{ $t('products.platform.platformTech.offeringData.text1') }}
              </p>
              <p class="listText">
                {{ $t('products.platform.platformTech.offeringData.text2') }}
              </p>
              <p class="listText">
                {{ $t('products.platform.platformTech.offeringData.text3') }}
              </p>
            </li>
          </ul>
        </div>
        <div class="blockchain" data-aos="fade-up" data-aos-duration="1000">
          <p>{{ $t('products.platform.blockchain.title') }}</p>
          <ul class="blockchainWrap">
            <li class="blockchainList reliability">
              <p class="blockchainImg">
                <img
                  src="../assets/images/products/ic-reliability.png"
                  alt="reliability-icon"
                />
              </p>
              <p class="blockchainTitle">
                {{ $t('products.platform.blockchain.reliability.title') }}
              </p>
              <p class="blockchainText">
                {{ $t('products.platform.blockchain.reliability.text1') }}
              </p>
              <p class="blockchainText">
                {{ $t('products.platform.blockchain.reliability.text2') }}
              </p>
              <p class="blockchainText">
                {{ $t('products.platform.blockchain.reliability.text3') }}
              </p>
            </li>
            <li class="blockchainList transparency">
              <p class="blockchainImg">
                <img
                  src="../assets/images/products/ic-transparency.png"
                  alt="transparency-icon"
                />
              </p>
              <p class="blockchainTitle">
                {{ $t('products.platform.blockchain.transparency.title') }}
              </p>
              <p class="blockchainText">
                {{ $t('products.platform.blockchain.transparency.text1') }}
              </p>
              <p class="blockchainText">
                {{ $t('products.platform.blockchain.transparency.text2') }}
              </p>
              <p class="blockchainText">
                {{ $t('products.platform.blockchain.transparency.text3') }}
              </p>
            </li>
            <li class="blockchainList organization">
              <p class="blockchainImg">
                <img
                  src="../assets/images/products/ic-organization.png"
                  alt="organization-icon"
                />
              </p>
              <p class="blockchainTitle">
                {{ $t('products.platform.blockchain.organization.title') }}
              </p>
              <p class="blockchainText">
                {{ $t('products.platform.blockchain.organization.text1') }}
              </p>
              <p class="blockchainText">
                {{ $t('products.platform.blockchain.organization.text2') }}
              </p>
              <p class="blockchainText">
                {{ $t('products.platform.blockchain.organization.text3') }}
              </p>
            </li>
          </ul>
        </div>
      </section>
      <!--  02. sesumeter -->
      <section id="sesuMeter">
        <h3 class="blind">sesu meter</h3>
        <div
          class="sectionTitle center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h4 class="subTitle">
            {{ $t('products.sesumeter.meterTitle.title') }}
            <img
              src="../assets/images/bee-icon.png"
              alt="bee-icon"
              class="beeIcon"
              aria-hidden="true"
            />
          </h4>
          <p class="titleDescription">
            {{ $t('products.sesumeter.meterTitle.desc1') }}
          </p>
          <p class="titleDescription">
            {{ $t('products.sesumeter.meterTitle.desc2') }}
          </p>
          <p class="titleDescription">
            {{ $t('products.sesumeter.meterTitle.desc3') }}
          </p>
        </div>
        <div class="sesumeterImg" data-aos="fade-up" data-aos-duration="1000">
          <p>
            <img
              src="../assets/images/products/sesumeter-pc.png"
              alt="sesu-sesumeter-title"
            />
          </p>
          <div class="sesumeter">
            <img
              src="../assets/images/products/sesumeter.png"
              alt="sesumeter"
              class="sesumeterPc"
            />
            <img
              src="../assets/images/products/sesumeter-tab.png"
              alt="sesumeter"
              class="sesumeterTab"
            />
            <img
              src="../assets/images/products/sesumeter-m.png"
              alt="sesumeter"
              class="sesumeterM"
            />
          </div>
        </div>
        <div
          class="sesumeterTech center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <ul class="technologyWrap">
            <li class="technologyList message">
              <p class="techImg">
                <img
                  src="../assets/images/products/ic-message.png"
                  alt="message-icon"
                />
              </p>
              <p class="listTitle">
                {{ $t('products.sesumeter.sesumeterTech.message.title') }}
              </p>
              <span class="technologyBar" aria-hidden="true"></span>
              <p class="listText">
                {{ $t('products.sesumeter.sesumeterTech.message.text1') }}
              </p>
              <p class="listText">
                {{ $t('products.sesumeter.sesumeterTech.message.text2') }}
              </p>
              <p class="listText">
                {{ $t('products.sesumeter.sesumeterTech.message.text3') }}
              </p>
            </li>
            <li class="technologyList communication">
              <p class="techImg">
                <img
                  src="../assets/images/products/ic-communication.png"
                  alt="communication-icon"
                />
              </p>
              <p class="listTitle">
                {{ $t('products.sesumeter.sesumeterTech.communication.title') }}
              </p>
              <span class="technologyBar" aria-hidden="true"></span>
              <p class="listText">
                {{ $t('products.sesumeter.sesumeterTech.communication.text1') }}
              </p>
              <p class="listText">
                {{ $t('products.sesumeter.sesumeterTech.communication.text2') }}
              </p>
              <p class="listText">
                {{ $t('products.sesumeter.sesumeterTech.communication.text3') }}
              </p>
            </li>
            <li class="technologyList security">
              <p class="techImg">
                <img
                  src="../assets/images/products/ic-security.png"
                  alt="security-icon"
                />
              </p>
              <p class="listTitle">
                {{ $t('products.sesumeter.sesumeterTech.security.title') }}
              </p>
              <span class="technologyBar" aria-hidden="true"></span>
              <p class="listText">
                {{ $t('products.sesumeter.sesumeterTech.security.text1') }}
              </p>
              <p class="listText">
                {{ $t('products.sesumeter.sesumeterTech.security.text2') }}
              </p>
              <p class="listText">
                {{ $t('products.sesumeter.sesumeterTech.security.text3') }}
              </p>
            </li>
          </ul>
        </div>
      </section>
      <!--  03. sesu DemoSystem -->
      <section
        id="sesuDemo"
        class="center"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h3 class="blind">sesu DemoSystem</h3>
        <h4 class="demoTitle">{{ $t('products.demoSystem.title') }}</h4>
        <div class="demoBoardWrap">
          <div class="demoList demoHW">
            <p class="demoImg">
              <img
                src="../assets/images/products/sesuboard-hw.jpg"
                alt="sesuboard-hw"
              />
            </p>
            <p class="boardTitle">
              {{ $t('products.demoSystem.demoBoard.demoHW.title') }}
            </p>
            <p class="boardText">
              {{ $t('products.demoSystem.demoBoard.demoHW.text1') }}
            </p>
            <p class="boardText">
              {{ $t('products.demoSystem.demoBoard.demoHW.text2') }}
            </p>
          </div>
          <div class="demoList demoSW">
            <p class="demoImg">
              <img
                src="../assets/images/products/sesuboard-sw.jpg"
                alt="sesuboard-sw"
              />
            </p>
            <p class="boardTitle">
              {{ $t('products.demoSystem.demoBoard.demoSW.title') }}
            </p>
            <p class="boardText">
              {{ $t('products.demoSystem.demoBoard.demoSW.text1') }}
            </p>
            <p class="boardText">
              {{ $t('products.demoSystem.demoBoard.demoSW.text2') }}
            </p>
          </div>
        </div>
      </section>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from '../components/TheFooter.vue';
import TheHeader from '../components/TheHeader.vue';
export default {
  components: {TheHeader, TheFooter},
};
</script>

<style lang="scss" scoped>
#productPage {
  #container {
    .pageTitle {
      @include pageTitle();

      @include respond($t_width) {
        @include pageTitleT();
      }

      @include respond($m_width) {
        @include pageTitleM();
      }
    }

    #sesuPlatform {
      .sectionTitle {
        margin-bottom: 140px;

        @include respond($t_width) {
          margin-bottom: 70px;
        }

        @include respond($m_width) {
          margin-bottom: 44px;
        }

        .subTitle {
          @include subTitle;

          @include respond($t_width) {
            @include subTitleT;
          }

          .beeIcon {
            @include beeIcon;

            @include respond($t_width) {
              @include beeIconT;
            }

            @include respond($m_width) {
              @include beeIconM;
            }
          }
        }

        .titleDescription {
          @include titleDescription;

          @include respond($t_width) {
            @include titleDescriptionT;
          }

          @include respond($m_width) {
            @include titleDescriptionM;
          }
        }
      }

      .platformImg {
        margin: 0 20px 50px;
        background-color: #ffffff;

        @include respond($t_width) {
          margin: 0 0 20px;
        }

        @include respond($m_width) {
          margin: 0 0 22px;
        }

        p {
          margin-bottom: 40px;

          @include respond($t_width) {
            margin-bottom: 20px;

            img {
              width: 178px;
              height: auto;
            }
          }

          @include respond($m_width) {
            margin-bottom: 14px;

            img {
              width: 283px;
            }
          }
        }

        .img {
          width: 1360px;
          height: 665px;
          #sesuPlatformImg {
            width: 814px;
          }
          @include borderStyle;
          text-align: center;

          @include respond($t_width) {
            width: 100%;
            height: 430px;

            #sesuPlatformImg {
              width: 521.38px;
            }
          }

          @include respond($m_width) {
            height: 260px;

            #sesuPlatformImg {
              width: 311px;
            }
          }
        }
      }

      .platformTech {
        margin: 0 20px;

        @include respond($t_width) {
          margin: 0;
        }

        .technologyWrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: space-between;

          @include respond($m_width) {
            justify-content: center;
          }

          .technologyList {
            display: block;
            width: 650px;
            height: 292px;
            @include borderStyle;
            text-align: center;
            margin-bottom: 16px;
            background-color: #ffffff;
            transition: all 0.5s;

            @include respond($t_width) {
              width: 42.7083vw;
              height: 140px;
              margin-bottom: 20px;
            }

            @include respond($m_width) {
              width: 100%;
              height: 120px;
              margin-bottom: 8px;
            }

            .technologyBar {
              display: block;
              margin: 36px auto 20px;
              @include greenBar(120px, 8px);
              transition: all 0.5s;

              @include respond($t_width) {
                margin: 15px auto 9px;
                @include greenBar(58px, 4px);
              }
            }

            .listTitle {
              width: 430px;
              height: 56px;
              font-weight: 700;
              font-size: 22px;
              color: $newsTitleColor;
              line-height: 30px;
              margin: 0 auto;
              transition: all 0.5s;

              @include respond($t_width) {
                width: 280px;
                height: 28px;
                line-height: 100%;
                font-size: 14px;
                margin-bottom: 25px;
              }

              @include respond($m_width) {
                margin-bottom: 12px;
              }
            }

            .listText {
              width: 490px;
              font-size: 18px;
              color: $newsContentColor;
              line-height: 32px;
              margin: 0 auto;
              transition: all 0.5s;

              @include respond($t_width) {
                width: 280px;
                font-size: 12px;
                line-height: 16.8px;
              }

              &.listTestFirst {
                margin: 40px auto 0;

                @include respond($t_width) {
                  margin: 0 auto 0;
                }
              }
            }

            &:hover {
              background-color: transparent;

              .technologyBar {
                background-color: #ffffff;
              }

              .listTitle,
              .listText {
                color: #ffffff;
              }

              @include respond($t_width) {
                background-color: white;

                .technologyBar {
                  background-color: $mainColor;
                }

                .listTitle {
                  color: $newsTitleColor;
                }

                .listText {
                  color: $newsContentColor;
                }
              }
            }

            &.recordData {
              &:hover {
                background-image: url('../assets/images/products/record-data.png');
              }
            }

            &.certifyData {
              &:hover {
                background-image: url('../assets/images/products/certify-data.png');
              }
            }

            &.statistict {
              &:hover {
                background-image: url('../assets/images/products/statistics-production.png');
              }
            }

            &.offeringData {
              &:hover {
                background-image: url('../assets/images/products/offering-data.png');
              }
            }

            @include respond($t_width) {
              &.recordData,
              &.certifyData,
              &.statistict,
              &.offeringData {
                &:hover {
                  background-image: none;
                }
              }
            }
          }
        }
      }

      .blockchain {
        width: 1360px;
        height: 365px;
        background-color: #f6f6f6;
        margin: 24px 20px 160px;
        padding: 0 140px;
        @include borderStyle;

        @include respond($t_width) {
          width: 100%;
          height: 220px;
          margin: 30px 0 90px;
          padding: 0 40px;
        }

        @include respond($m_width) {
          height: 656px;
          margin: 60px 0 99px;
          padding: 0;
        }

        > p {
          font-weight: 700;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.8);
          margin: 24px 0 31px;
          text-align: center;

          @include respond($t_width) {
            font-size: 24px;
            margin: 20px 0 25px;
          }

          @include respond($m_width) {
            font-size: 32px;
            margin: 24px 0 55px;
          }
        }

        .blockchainWrap {
          display: flex;
          justify-content: space-between;

          @include respond($m_width) {
            flex-direction: column;
            align-items: center;
          }

          .blockchainList {
            display: block;
            width: 320px;
            text-align: center;

            @include respond($t_width) {
              width: 200px;
            }

            @include respond($m_width) {
              width: 320px;
              margin-bottom: 15px;
            }

            .blockchainImg {
              width: 55px;
              height: 55px;
              margin: 0 auto 25px;

              @include respond($t_width) {
                width: 30px;
                height: 30px;
                margin: 0 auto 10px;

                img {
                  width: 30px;
                  height: auto;
                }
              }

              @include respond($m_width) {
                width: 46px;
                height: 46px;
                margin: 0 auto 20px;

                img {
                  width: 46px;
                  height: auto;
                }
              }
            }

            .blockchainTitle {
              font-size: 18px;
              margin-bottom: 39px;

              @include respond($t_width) {
                font-size: 14px;
                margin-bottom: 20px;
              }

              @include respond($m_width) {
                font-size: 16px;
                margin-bottom: 16px;
              }
            }

            .blockchainText {
              font-size: 16px;
              color: $newsContentColor;
              line-height: 30px;
              width: 320px;

              @include respond($t_width) {
                font-size: 12px;
                line-height: 15px;
                width: 200px;
              }

              @include respond($m_width) {
                font-size: 14px;
                line-height: 23px;
                width: 320px;
              }
            }
          }
        }
      }
    }

    #sesuMeter {
      .sectionTitle {
        margin-bottom: 100px;

        @include respond($t_width) {
          margin-bottom: 60px;
        }

        @include respond($m_width) {
          margin-bottom: 60px;
        }

        .subTitle {
          @include subTitle;

          @include respond($t_width) {
            @include subTitleT;
          }

          .beeIcon {
            @include beeIcon;

            @include respond($t_width) {
              @include beeIconT;
            }

            @include respond($m_width) {
              @include beeIconM;
            }
          }
        }

        .titleDescription {
          @include titleDescription;

          @include respond($t_width) {
            @include titleDescriptionT;
          }

          @include respond($m_width) {
            @include titleDescriptionM;
          }
        }
      }

      .sesumeterImg {
        width: 1360px;
        margin: 0 auto;

        @include respond($t_width) {
          width: 100%;
          margin: 0;
        }

        p {
          margin-bottom: 40px;

          @include respond($t_width) {
            margin-left: 34px;
            margin-bottom: 20px;

            img {
              width: 119px;
              height: auto;
            }
          }

          @include respond($m_width) {
            margin-left: 20px;
            margin-bottom: 24px;
          }
        }

        .sesumeter {
          width: 1360px;

          .sesumeterTab,
          .sesumeterM {
            display: none;
          }

          @include respond($t_width) {
            width: 100%;

            img {
              width: 100%;

              &.sesumeterPc {
                display: none;
              }

              &.sesumeterTab {
                display: block;
              }
            }
          }

          @include respond($m_width) {
            img {
              &.sesumeterTab {
                display: none;
              }

              &.sesumeterM {
                display: block;
              }
            }
          }
        }
      }

      .sesumeterTech {
        margin: 38px auto 100px;

        @include respond($t_width) {
          margin: 60px auto 70px;
        }

        @include respond($m_width) {
          margin: 24px auto 80px;
        }

        .technologyWrap {
          display: flex;
          justify-content: space-around;

          @include respond($t_width) {
            flex-direction: column;
            align-items: center;
          }

          .technologyList {
            display: block;
            width: 420px;
            height: 240px;
            text-align: center;
            @include borderStyle;
            background-color: #ffffff;

            @include respond($t_width) {
              width: 80.7292vw;
              height: 180px;
              margin-bottom: 20px;
            }

            @include respond($m_width) {
              width: 88.8889vw;
              height: 172px;
              margin-bottom: 10px;
            }

            .techImg {
              width: 51.33px;
              margin: 19.5px auto;

              @include respond($t_width) {
                img {
                  width: 33px;
                  height: auto;
                }

                margin: 14.25px auto 14.25px;
              }

              @include respond($m_width) {
                img {
                  width: 27px;
                }

                margin: 14.5px auto 10.5px;
              }
            }

            .listTitle {
              font-weight: 700;
              font-size: 20px;
              color: rgba(0, 0, 0, 0.8);
              margin-bottom: 20px;

              @include respond($t_width) {
                font-size: 16px;
                margin-bottom: 14px;
              }

              @include respond($m_width) {
                margin-bottom: 13px;
              }
            }

            .technologyBar {
              display: block;
              @include greenBar(56px, 8px);
              margin: 0 auto 14px;

              @include respond($t_width) {
                @include greenBar(56px, 6px);
                margin: 0 auto 16px;
              }

              @include respond($m_width) {
                @include greenBar(46px, 4px);
                margin: 0 auto 12px;
              }
            }

            .listText {
              width: 390px;
              font-size: 14px;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.7);
              margin: 0 auto;

              @include respond($t_width) {
                font-size: 13px;
                line-height: 20px;
              }

              @include respond($m_width) {
                width: 300px;
                font-size: 12px;
                line-height: 19px;
              }
            }
          }
        }
      }
    }

    #sesuDemo {
      margin-bottom: 140px;

      @include respond($t_width) {
        margin-bottom: 80px;
      }

      @include respond($m_width) {
        margin-bottom: 52px;
      }

      .demoTitle {
        font-weight: 700;
        font-size: 40px;
        margin: 0 0 40px 20px;

        @include respond($t_width) {
          font-size: 20px;
          margin: 0 0 20px 0;
        }
      }

      .demoBoardWrap {
        display: flex;
        justify-content: space-between;

        @include respond($m_width) {
          flex-direction: column;
          align-items: center;
        }

        .demoList {
          width: 675px;
          height: 580px;
          padding: 20px 40px;
          @include borderStyle();
          background-color: #ffffff;

          @include respond($t_width) {
            width: 44.2708vw;
            height: 41.6667vw;
            padding: 10px 20px 0;
          }

          @include respond($m_width) {
            width: 88.8889vw;
            height: 88.8889vw;
            padding: 10px 10px 0;
            margin-bottom: 10px;
          }

          .demoImg {
            @include respond($t_width) {
              img {
                width: 39.0625vw;
              }
            }

            @include respond($m_width) {
              img {
                width: 83.3333vw;
              }
            }
          }

          .boardTitle {
            font-weight: 700;
            font-size: 40px;
            margin: 32px 0 34px;

            @include respond($t_width) {
              font-size: 20px;
              margin: 15px 0 16px;
            }
          }

          .boardText {
            width: 500px;
            font-size: 20px;
            color: $newsTitleColor;
            line-height: 36px;

            @include respond($t_width) {
              width: 300px;
              font-size: 12px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
