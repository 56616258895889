<template>
  <div id="newsPage">
    <the-header></the-header>
    <div id="container">
      <h2 class="pageTitle">{{ $t('news.pageTitle') }}</h2>
      <section id="sesuNews" class="center" data-aos="fade-up" data-aos-duration="1000">
        <h3 class="blind">sesu news</h3>
        <div class="sectionTitle">
          <h4 class="subTitle">
            {{ $t('news.sesuNewsTitle.title') }}
            <img
              src="../assets/images/bee-icon.png"
              alt="bee-icon"
              class="beeIcon"
              aria-hidden="true"
            />
          </h4>
          <p class="titleDescription">{{ $t('news.sesuNewsTitle.desc1') }}</p>
          <p class="titleDescription">{{ $t('news.sesuNewsTitle.desc2') }}</p>
        </div>
      </section>
      <section id="sesuNewsList" class="center" data-aos="fade-up" data-aos-duration="1000">
        <div class="newsListWrap">
          <ul class="newsWrap" v-show="this.lang === 'ko'">
            <li class="newsList"  v-for="(value, index) in newsContents.data" :key="index">
              <a :href="value.link" target="_blank">
                <div class="newsImage">
                  <img
                    :src="`${REQUEST_URL}thumbnails/news/${value.thumbnail}`"
                    alt="news-images"
                  />
                </div>
                <div class="newsTextWrap">
                  <span class="newsDate">{{ $moment.unix(value.date).format('YYYY.MM.DD') }}</span>
                  <p class="newsContent">
                    {{ value.abstract_ko }}
                  </p>
                </div>
              </a>
            </li>
          </ul>
          <ul class="newsWrap" v-show="this.lang === 'en'">
            <li class="newsList"  v-for="(value, index) in newsContents.data" :key="index">
              <a :href="value.link" target="_blank">
                <div class="newsImage">
                  <img
                    :src="`${REQUEST_URL}thumbnails/news/${value.thumbnail}`"
                    alt="news-images"
                  />
                </div>
                <div class="newsTextWrap">
                  <span class="newsDate">{{ $moment.unix(value.date).format('YYYY.MM.DD') }}</span>
                  <p class="newsContent">
                    {{ value.abstract_en }}
                  </p>
                </div>
              </a>
            </li>
          </ul>
          <div class="paginationWrap">
            <b-pagination
              v-model="newsContents.currentPage"
              :total-rows="newsContents.total"
              :per-page="newsContents.pageSize"
              :limit=pageLimit
              hide-ellipsis
              aria-controls="my-table"
              @change="onClickPagination"
            >
              <template #first-text>
                <span class="text-info">
                  <img
                src="../assets/images/newstopic/ic-firstarrow.png"
                alt="first-arrow"
              />
                </span>
              </template>
              <template #prev-text>
                <span class="text-info">
                  <img
                src="../assets/images/newstopic/ic-prevarrow.png"
                alt="prev-arrow"
              />
                </span>
              </template>
              <template #next-text>
                <span class="text-info">
                  <img
                src="../assets/images/newstopic/ic-nextarrow.png"
                alt="next-arrow"
              />
                </span>
              </template>
              <template #last-text>
                <span class="text-info">
                  <img
                src="../assets/images/newstopic/ic-lastarrow.png"
                alt="last-arrow"
              />
                </span>
              </template>
              <template #page="{page, active}">
                <span v-if="active" class="active">
                  {{ page }}
                </span>
                <span v-else>{{ page }}</span>
              </template>
            </b-pagination>
          </div>
        </div>
      </section>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import axios from 'axios';
import {REQUEST_URL} from '../serverProtocol.js';
import TheFooter from '../components/TheFooter.vue';
import TheHeader from '../components/TheHeader.vue';
export default {
  components: {TheHeader, TheFooter},
  data() {
    return {
      lang: this.$store.state.lang,
      newsContents: {
        total: 0,
        currentPage: 1,
        pageSize: 5,
        data: [],
      },
      pageLimit: '10',
      REQUEST_URL: REQUEST_URL,
    }
  },
  mounted() {
    /**
     * 화면 전환할 때마다 페이지 리밋 변화
     */
    this.handleResize();
    /**
     * 화면 사이즈가 바뀌면 페이징이 변경되는 resize 이벤트 
     */
    window.addEventListener('resize', this.handleResize);
    /**
     * mounted될 때 main화면의 뉴스 탭에 자료 가져와서 출력하기
     */
    const newsOptions = {
      method: 'GET',
      url: `${REQUEST_URL}api/news`,
      params: {page: this.newsContents.currentPage , pageSize: '5'}
    };

    axios.request(newsOptions).then((response) => {
      this.newsContents = response.data;
    }).catch((error) => {
      console.error(error);
    });
  },
  beforeDestroy() {
    /**
     * resize 이벤트 삭제
     */
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    /**
     * 페이징 이벤트
     */
    onClickPagination(value) {
      this.newsContents.currentPage = value;
      this.getNewsContents();
    },
    /**
     * 화면 사이즈가 변경될 때 페이지 리밋이 변경되는 이벤트
     */
    handleResize() {
      if (window.innerWidth <= 1420) {
        this.pageLimit = '5';
      } else {
        this.pageLimit = '10';
      }
    },
    /**
     * 페이지 바뀔 때마다 뉴스 탭에 자료 가져와서 출력하기
     */
    getNewsContents() {
      const newsOptions = {
        method: 'GET',
        url: `${REQUEST_URL}api/news`,
        params: {page: this.newsContents.currentPage , pageSize: '5'}
      };

      axios.request(newsOptions).then((response) => {
        this.newsContents = response.data;
      }).catch((error) => {
        console.error(error);
      });
    }
  },
  computed: {
    checkLang() {
      return this.$store.state.lang;
    }
  },
  watch: {
    checkLang(value) {
      this.lang = value;
    }
  },
};
</script>

<style lang="scss">
#newsPage {
  #container {
    .pageTitle {
      @include pageTitle(url('../assets/images/newstopic/news-title-bg.png'));

      @include respond($t_width) {
        @include pageTitleT(url('../assets/images/newstopic/news-title-bg.png'));
      }

      @include respond($m_width) {
        @include pageTitleM(url('../assets/images/newstopic/news-title-bg.png'));
      }
    }

    #sesuNews {
      margin-bottom: 128px;

      @include respond($t_width) {
        margin-bottom: 40px;
      }

      @include respond($m_width) {
        margin-bottom: 60px;
      }

      .sectionTitle {
        .subTitle {
          @include subTitle;

          @include respond($t_width) {
            @include subTitleT;
          }

          .beeIcon {
            @include beeIcon;

            @include respond($t_width) {
              @include beeIconT;
            }

            @include respond($m_width) {
              @include beeIconM;
            }
          }
        }

        .titleDescription {
          @include titleDescription;

          @include respond($t_width) {
            @include titleDescriptionT;
          }

          @include respond($m_width) {
            @include titleDescriptionM;
          }
        }
      }
    }

    #sesuNewsList{
      .newsWrap {
        margin: 0 190px;

        @include respond($t_width) {
          margin: 0;
        }
        
        .newsList {
          a {
            display: flex;
            width: 1020px;
            height: 160px;
            background-color: #ffffff;
            @include borderStyle;
            padding: 8px 12px;
            margin-bottom: 10px;
            cursor: pointer;

            @include respond($t_width) {
              max-width: 91.1458vw;
              margin: 0 auto 10px;
            }

            @include respond($m_width) {
              flex-direction: column;
              width: 320px;
              height: 250px;
              margin: 0 auto 10px;
              padding: 10px 10px 0;
            }

            .newsImage {
              width: 320px;
              margin-right: 40px;
              align-content: center;

              img {
                max-width: 100%;
              }

              @include respond($m_width) {
                width: 300px;
                margin: 0 auto 10px;
                img {
                  max-width: 100%;
                }
              }
            }

            .newsTextWrap {

              @include respond($m_width) {
                margin: 0 auto;
              }

              .newsDate {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.7);
                line-height: 16px;

                @include respond($t_width) {
                  font-size: 12px;
                  line-height: 14px;
                }
              }

              .newsContent {
                width: 440px;
                height: 110px;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.9);
                margin-top: 8px;
                line-height: 27px;
                text-overflow: ellipsis;
                overflow: hidden;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;

                @include respond($t_width) {
                  max-width: 40.1042vw;
                  font-size: 14px;
                  line-height: 19.6px;
                  height: 100px;
                  -webkit-line-clamp: 5;
                }

                @include respond($m_width) {
                  margin-top: 4px;
                  max-width: 300px;
                  width: 300px;
                  height: 60px;
                  -webkit-line-clamp: 3;
                }
              }
            }

            &:hover, &:active {
              background-color: #f0f0f0;
            }
          }
        }
      }

      .paginationWrap {
        cursor: pointer;
        margin: 30px auto 130px;

        @include respond($t_width) {
          margin: 30px auto 60px;
        }
        
        @include respond($m_width) {
          margin: 30px auto 70px;
        }

        .pagination {
          display: flex;
          justify-content: center;

          li {
            width: 32px;
            height: 32px;
            box-sizing: border-box;
            text-align: center;
            line-height: 32px;

            button, span {
              background-color: transparent;
              font-size: 16px;
              font-family: 'Noto Sans KR', sans-serif;
              color: rgba(0, 0, 0, 0.9);
            }

            button {
              span {
                &.active {
                  color: #FF6939;
                }
              }
            }

            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }
    }
  }
}
</style>