<template>
  <div>
    <header>
      <div class="center">
        <!-- sesu logo -->
        <h1 class="sesuLogo">
          <router-link to="/">
            <img
              src="../assets/images/headerfooter/sesuai-logo.png"
              alt="sesuai-logo"
            />
          </router-link>
        </h1>
        <!-- nav -->
        <nav>
          <ul id="nav">
            <li>
              <router-link to="/">{{ $t('header.main') }}</router-link>
            </li>
            <li>
              <router-link to="/products">{{
                $t('header.products')
              }}</router-link>
            </li>
            <li>
              <router-link to="/news">{{ $t('header.news') }}</router-link>
            </li>
            <li>
              <router-link to="/topic">{{ $t('header.topic') }}</router-link>
            </li>
          </ul>
        </nav>
        <!-- gnb -->
        <ul class="gnb">
          <li class="basicLang langIcon">
            <img
              src="../assets/images/headerfooter/ic-lang.png"
              alt="lang-icon"
            />
          </li>
          <li
            class="basicLang langKo"
            @click="onClickLangKO"
            :class="[lang === 'ko' ? currentLang : basicLang]"
          >
            KO
          </li>
          <li
            class="basicLang langEn"
            @click="onClickLangEN"
            :class="[lang === 'en' ? currentLang : basicLang]"
          >
            EN
          </li>
        </ul>
        <!-- mobile -->
        <div class="nav_mobile">
          <!-- 메뉴 아이콘 -->
          <p class="menuIcon" @click="onClickNav">
            <img
              src="../assets/images/headerfooter/ic-menu.png"
              alt="menu icon"
            />
          </p>
          <!-- nav -->
          <div class="mNavWrap" :style="mNavStyle">
            <ul id="mNav">
              <li>
                <router-link to="/">
                  <span class="navDeco"></span>
                  {{ $t('header.main') }}
                </router-link>
              </li>
              <li>
                <router-link to="/products">
                  <span class="navDeco"></span>
                  {{ $t('header.products') }}</router-link
                >
              </li>
              <li>
                <router-link to="/news">
                  <span class="navDeco"></span>
                  {{ $t('header.news') }}</router-link
                >
              </li>
              <li>
                <router-link to="/topic">
                  <span class="navDeco"></span>
                  {{ $t('header.topic') }}</router-link
                >
              </li>
            </ul>
            <ul id="mGnb">
              <li class="basicLang langIcon">
                <img
                  src="../assets/images/headerfooter/ic-lang-m.png"
                  alt="lang-icon"
                />
              </li>
              <li
                class="basicLang langKo"
                @click="onClickLangKO"
                :class="[lang === 'ko' ? currentLang : basicLang]"
              >
                KO
              </li>
              <li
                class="basicLang langEn"
                @click="onClickLangEN"
                :class="[lang === 'en' ? currentLang : basicLang]"
              >
                EN
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: this.$i18n.locale,
      currentLang: 'currentLang',
      basicLang: 'basicLang',
      mNavStyle: {
        top: '-310px'
      }
    };
  },
  methods: {
    onClickLangKO() {
      this.lang = 'ko';
      this.$i18n.locale = 'ko';
      this.$store.commit('lang', 'ko');
    },
    onClickLangEN() {
      this.lang = 'en';
      this.$i18n.locale = 'en';
      this.$store.commit('lang', 'en');
    },
    onClickNav() {
      if (this.mNavStyle.top === '-310px') {
        this.mNavStyle.top = '60px';
      } else if (this.mNavStyle.top === '60px') {
        this.mNavStyle.top = '-310px';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  height: 64px;
  background-color: #ffffff;
  border-bottom: 1px solid $hoverColor;

  @include respond($m_width) {
    position: relative;
    height: 60px;
  }

  .center {
    line-height: 64px;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;

    @include respond($m_width) {
      justify-content: space-between;
    }

    .sesuLogo {
      @include respond($t_width) {
        width: 140px;
        height: 24px;
      }

      img {
        margin-top: 13px;
        margin-left: 30px;

        @include respond($t_width) {
          width: 100%;
          margin: 20px 45px 0 0;
        }

        @include respond($m_width) {
          margin: 18px 0;
        }
      }
    }

    nav {
      @include respond($m_width) {
        display: none;
      }

      #nav {
        li {
          display: inline-block;
          width: 160px;
          text-align: center;
          margin-right: 28px;
          font-weight: 700;
          font-size: 18px;
          line-height: 64px;

          @include respond($t_width) {
            width: 80px;
            font-size: 16px;
            margin-right: 20px;
          }

          .router-link-exact-active {
            color: $mainColor;
          }
        }
      }
    }

    .gnb {
      margin-right: 30px;
      position: relative;

      @include respond($t_width) {
        margin-right: 0px;
      }

      @include respond($m_width) {
        display: none;
      }

      .basicLang {
        display: inline-block;
        width: 40px;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 64px;

        @include respond($t_width) {
          font-size: 16px;
          width: 35px;
        }

        &.currentLang {
          color: $mainColor;
        }

        &.langIcon {
          img {
            position: absolute;
            top: 30%;
            left: 10%;

            @include respond($t_width) {
              width: 20px;
              top: 35%;
            }
          }
        }

        &.langKo {
          cursor: pointer;

          &::before {
            content: '';
            display: block;
            width: 2px;
            height: 14px;
            background-color: #000000;
            position: absolute;
            left: 65%;
            top: 40%;
          }
        }

        &.langEn {
          cursor: pointer;
        }
      }
    }

    .nav_mobile {
      display: none;

      @include respond($m_width) {
        display: block;
      }

      .menuIcon {
        margin: 22px 0;
        cursor: pointer;
      }

      .mNavWrap {
        display: block;
        z-index: 2;
        position: absolute;
        left: 0;
        width: 100%;
        height: 301px;
        padding: 10px;
        background-color: #ffffff;
        box-sizing: border-box;

        #mNav {
          width: 100%;
          height: 200px;
          margin-bottom: 10px;
          @include borderStyle;
          box-sizing: border-box;

          li {
            width: 156px;
            height: 23px;
            font-weight: 700;
            font-size: 16px;
            margin-left: 20px;
            margin-bottom: 20px;

            &:first-child {
              margin-top: 24px;
            }

            a {
              color: $newsContentColor;

              .navDeco {
                display: inline-block;
                width: 20px;
                height: 6px;
                margin-right: 16px;
                background-color: $newsContentColor;
                border-radius: 3px;
              }
            }

            .router-link-exact-active {
              color: $mainColor;

              .navDeco {
                background-color: $mainColor;
              }
            }

            
          }
        }

        #mGnb {
          width: 100%;
          height: 71px;
          position: relative;
          @include borderStyle;
          box-sizing: border-box;

          .basicLang {
            display: inline-block;
            font-weight: 700;
            font-size: 16px;
            width: 44px;
            height: 23px;
            color: #555555;
            line-height: 71px;

            &.currentLang {
              color: $mainColor;
            }

            &.langIcon {
              img {
                position: absolute;
                top: 24px;
                left: 10px;
              }
            }

            &.langKo {
              cursor: pointer;
              margin-left: 7px;

              &::before {
                content: '';
                display: block;
                width: 2px;
                height: 20px;
                background-color: #555555;
                position: absolute;
                left: 85px;
                top: 25px;
              }
            }

            &.langEn {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
