<template>
  <div id="homePage">
    <the-header></the-header>
    <div class="sesuMovie">
      <img src="../assets/images/bg-main-pc.jpg" alt="sesu main image" />
      <span class="saveU">SAVE EARTH, <br> SAVE US</span>
    </div>
    <div id="container">
      <!-- 01. sesu Message -->
      <p class="sesuMessage center" data-aos="fade-up" data-aos-duration="1000">
        {{ $t('main.message') }}
      </p>
      <!-- 02. sesu Goal -->
      <section
        id="sesuGoal"
        class="center"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h3 class="blind">sesu goal</h3>
        <div class="sectionTitle">
          <h4 class="subTitle">
            {{ $t('main.goal.goalTitle.title') }}
            <img
              src="../assets/images/bee-icon.png"
              alt="bee-icon"
              class="beeIcon"
              aria-hidden="true"
            />
          </h4>
          <p class="titleDescription">{{ $t('main.goal.goalTitle.desc1') }}</p>
          <p class="titleDescription">{{ $t('main.goal.goalTitle.desc2') }}</p>
          <p class="titleDescription">{{ $t('main.goal.goalTitle.desc3') }}</p>
        </div>
        <!-- 한글 버전 -->
        <ul class="goalButton goalKo" v-show="lang === 'ko'">
          <li
            class="goalB2b"
            @click="onClickGoalB2b"
            :class="[goal === 'b2b' ? goalBtnActive : goalBtnBasic]"
          >
            {{ $t('main.goal.goalButton.btnB2b') }}
            <span class="bar"></span>
          </li>
          <li
            class="goalRenewable"
            @click="onClickGoalRenewable"
            :class="[goal === 'renewable' ? goalBtnActive : goalBtnBasic]"
          >
            {{ $t('main.goal.goalButton.btnRenewable') }}
            <span class="bar"></span>
          </li>
          <li
            class="goalExtend"
            @click="onClickGoalExtend"
            :class="[goal === 'extend' ? goalBtnActive : goalBtnBasic]"
          >
            {{ $t('main.goal.goalButton.btnExtend') }}
            <span class="bar"></span>
          </li>
        </ul>
        <!-- 영문 버전 -->
        <ul class="goalButton goalEn" v-show="lang === 'en'">
          <li
            class="goalB2b"
            @click="onClickGoalB2b"
            :class="[goal === 'b2b' ? goalBtnActive : goalBtnBasic]"
          >
            {{ $t('main.goal.goalButton.btnB2b') }}
            <span class="bar"></span>
          </li>
          <li
            class="goalRenewable"
            @click="onClickGoalRenewable"
            :class="[goal === 'renewable' ? goalBtnActive : goalBtnBasic]"
          >
            {{ $t('main.goal.goalButton.btnRenewable') }}
            <span class="bar"></span>
          </li>
          <li
            class="goalExtend"
            @click="onClickGoalExtend"
            :class="[goal === 'extend' ? goalBtnActive : goalBtnBasic]"
          >
            {{ $t('main.goal.goalButton.btnExtend') }}
            <span class="bar"></span>
          </li>
        </ul>
        <div class="goalImgWrap">
          <div
            class="goalImg goalB2b"
            :class="[goal === 'b2b' ? goalImgB2b : goalImgBasic]"
          >
            <p>{{ $t('main.goal.goalImg.imgB2b.p') }}</p>
            <span>{{ $t('main.goal.goalImg.imgB2b.span') }}</span>
          </div>
          <div
            class="goalImg goalRenewable"
            :class="[goal === 'renewable' ? goalImgRenewable : goalImgBasic]"
          >
            <p>{{ $t('main.goal.goalImg.imgRenewable.p') }}</p>
            <span>
              {{ $t('main.goal.goalImg.imgRenewable.span') }}
            </span>
          </div>
          <div
            class="goalImg goalExtend"
            :class="[goal === 'extend' ? goalImgExtend : goalImgBasic]"
          >
            <p>{{ $t('main.goal.goalImg.imgExtend.p') }}</p>
            <span>
              {{ $t('main.goal.goalImg.imgExtend.span') }}
            </span>
          </div>
        </div>
        <!-- sesu Goal Img Tab ~ -->
        <div class="goalImgWrapTab">
          <div class="goalImg goalB2b">
            <p>{{ $t('main.goal.goalImg.imgB2b.p') }}</p>
            <span>{{ $t('main.goal.goalImg.imgB2b.span') }}</span>
          </div>
          <div class="goalImg goalRenewable">
            <p>{{ $t('main.goal.goalImg.imgRenewable.p') }}</p>
            <span>
              {{ $t('main.goal.goalImg.imgRenewable.span') }}
            </span>
          </div>
          <div class="goalImg goalExtend">
            <p>{{ $t('main.goal.goalImg.imgExtend.p') }}</p>
            <span>
              {{ $t('main.goal.goalImg.imgExtend.span') }}
            </span>
          </div>
        </div>
      </section>
      <!-- 03.sesu RE100 -->
      <section
        id="sesuRe100"
        class="center"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h3 class="blind">sesu RE100</h3>
        <div class="re100">
          <p class="re100Title">{{ $t('main.re100.title') }}</p>
          <span class="re100sub">{{ $t('main.re100.desc') }}</span>
          <span class="re100Bar" aria-hidden="true"></span>
          <p class="re100Text">{{ $t('main.re100.text1') }}</p>
          <p class="re100Text">{{ $t('main.re100.text2') }}</p>
          <p class="re100Text">{{ $t('main.re100.text3') }}</p>
          <span class="re100Bg" aria-hidden="true"> </span>
        </div>
      </section>
      <!-- 04. sesu News -->
      <section id="sesuNews" class="center">
        <h3 class="blind">sesu news</h3>
        <div class="sectionTitle" data-aos="fade-up" data-aos-duration="1000">
          <h4 class="subTitle">
            {{ $t('main.news.newsTitle.title') }}
            <img
              src="../assets/images/bee-icon.png"
              alt="bee-icon"
              class="beeIcon"
              aria-hidden="true"
            />
          </h4>
          <p class="titleDescription">{{ $t('main.news.newsTitle.desc1') }}</p>
          <p class="titleDescription">{{ $t('main.news.newsTitle.desc2') }}</p>
        </div>
        <div class="newsListWrap" data-aos="fade-up" data-aos-duration="1000">
          <ul class="newsWrap" v-show="this.lang === 'ko'">
            <li class="newsList" v-for="(value, index) in newsData.data" :key="index">
              <a
                :href="value.link"
                target="_blank"
              >
                <span class="newsBar" aria-hidden="true"></span>
                <p class="newsTitle">{{ value.title_ko }}</p>
                <p class="newsText">
                  {{ value.abstract_ko }}
                </p>
                <p class="newsDate">
                  <img
                    src="../assets/images/home/ic-clock.png"
                    alt="clock-icon"
                    class="clockIcon"
                    aria-hidden="true"
                  />
                  <img
                    src="../assets/images/home/ic-clock-hover.png"
                    alt="clock-hover-icon"
                    class="clockHoverIcon"
                    aria-hidden="true"
                  />
                  {{ $moment.unix(value.date).format('YYYY.MM.DD') }}
                </p>
              </a>
            </li>
          </ul>
          <ul class="newsWrap" v-show="this.lang === 'en'">
            <li class="newsList" v-for="(value, index) in newsData.data" :key="index">
              <a
                :href="value.link"
                target="_blank"
              >
                <span class="newsBar" aria-hidden="true"></span>
                <p class="newsTitle">{{ value.title_en }}</p>
                <p class="newsText">
                  {{ value.abstract_en }}
                </p>
                <p class="newsDate">
                  <img
                    src="../assets/images/home/ic-clock.png"
                    alt="clock-icon"
                    class="clockIcon"
                    aria-hidden="true"
                  />
                  <img
                    src="../assets/images/home/ic-clock-hover.png"
                    alt="clock-hover-icon"
                    class="clockHoverIcon"
                    aria-hidden="true"
                  />
                  {{ $moment.unix(value.date).format('YYYY.MM.DD') }}
                </p>
              </a>
            </li>
          </ul>
          <button class="moreButton" @click="onClickNewsMoreButton">
            {{ $t('main.news.newsList.btnMore') }}
            <img
              src="../assets/images/home/ic-more.png"
              alt="moreButton"
              aria-hidden="true"
            />
          </button>
        </div>
      </section>
      <!-- 05. sesu Products -->
      <section id="sesuProducts">
        <h3 class="blind">sesu products</h3>
        <div
          class="sectionTitle center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h4 class="subTitle">
            {{ $t('main.products.productTitle.title') }}
            <img
              src="../assets/images/bee-icon.png"
              alt="bee-icon"
              class="beeIcon"
              aria-hidden="true"
            />
          </h4>
          <p class="titleDescription">
            {{ $t('main.products.productTitle.desc1') }}
          </p>
          <p class="titleDescription">
            {{ $t('main.products.productTitle.desc2') }}
          </p>
        </div>
        <div class="productsWrap" data-aos="fade-up" data-aos-duration="1000">
          <p class="productsTitle">
            {{ $t('main.products.productWrap.title') }}
          </p>
          <p class="productsMore">
            <button class="gotoProducts" @click="onClickProductsMoreButton">
              {{ $t('main.products.productWrap.btnGo') }}
            </button>
          </p>
          <span class="blackBox" aria-hidden="true"></span>
        </div>
      </section>
      <!-- 06.sesu Topic -->
      <section id="sesuTopic" class="center">
        <h3 class="blind">sesu topic</h3>
        <div class="sectionTitle" data-aos="fade-up" data-aos-duration="1000">
          <h4 class="subTitle">
            {{ $t('main.topic.topicTitle.title') }}
            <img
              src="../assets/images/bee-icon.png"
              alt="bee-icon"
              class="beeIcon"
              aria-hidden="true"
            />
          </h4>
          <p class="titleDescription">
            {{ $t('main.topic.topicTitle.desc1') }}
          </p>
          <p class="titleDescription">
            {{ $t('main.topic.topicTitle.desc2') }}
          </p>
        </div>
        <div class="topicListWrap" data-aos="fade-up" data-aos-duration="1000">
          <ul class="topicWrap" v-show="this.lang === 'ko'">
            <li class="topicList" v-for="(value, index) in topicsData.data" :key="index">
              <a :href="value.link"
                target="_blank">
                <span class="newsBar" aria-hidden="true"></span>
                <p class="topicTitle">
                  {{ value.title_ko }}
                </p>
                <p class="topicDate">
                  <img
                    src="../assets/images/home/ic-clock.png"
                    alt="clock-icon"
                    class="clockIcon"
                    aria-hidden="true"
                  />
                  {{ $moment.unix(value.date).format('YYYY.MM.DD') }}
                </p>
              </a>
            </li>
          </ul>
          <ul class="topicWrap" v-show="this.lang === 'en'">
            <li class="topicList" v-for="(value, index) in topicsData.data" :key="index">
              <a :href="value.link"
                target="_blank">
                <span class="newsBar" aria-hidden="true"></span>
                <p class="topicTitle">
                  {{ value.title_en }}
                </p>
                <p class="topicDate">
                  <img
                    src="../assets/images/home/ic-clock.png"
                    alt="clock-icon"
                    class="clockIcon"
                    aria-hidden="true"
                  />
                  {{ $moment.unix(value.date).format('YYYY.MM.DD') }}
                </p>
              </a>
            </li>
          </ul>
          <button class="moreButton" @click="onClickTopicMoreButton">
            {{ $t('main.topic.topicList.btnMore') }}
            <img
              src="../assets/images/home/ic-more.png"
              alt="moreButton"
              aria-hidden="true"
            />
          </button>
        </div>
      </section>
      <!-- 07.sesu Link -->
      <section
        id="sesuLink"
        class="center"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h3 class="blind">sesu link</h3>
        <div class="sectionTitle">
          <h4 class="subTitle">
            {{ $t('main.link.title') }}
            <img
              src="../assets/images/bee-icon.png"
              alt="bee-icon"
              class="beeIcon"
              aria-hidden="true"
            />
          </h4>
        </div>
        <div class="linkListWrap">
          <ul class="linkWrap">
            <li class="linkList">
              <a href="https://sesuai-carbonmarket.github.io/" target="_blink">
                <img
                  src="../assets/images/home/link-img-09.png"
                  alt="link-logo"
                />
              </a>
            </li>
            <li class="linkList">
              <a href="https://www.born2global.com/" target="_blink">
                <img
                  src="../assets/images/home/link-img-08.png"
                  alt="link-logo"
                />
              </a>
            </li>
            <li class="linkList">
              <a href="https://www.google.com/" target="_blink">
                <img
                  src="../assets/images/home/link-img-07.png"
                  alt="link-logo"
                />
              </a>
            </li>
            <li class="linkList">
              <a href="http://www.jointips.or.kr/" target="_blink">
                <img
                  src="../assets/images/home/link-img-01.png"
                  alt="link-logo"
                />
              </a>
            </li>
            <li class="linkList">
              <a
                href="https://www.smes.go.kr/venturein/home/viewHome"
                target="_blink"
              >
                <img
                  src="../assets/images/home/link-img-02.png"
                  alt="link-logo"
                />
              </a>
            </li>
            <li class="linkList">
              <a href="https://www.rnd.or.kr/user/main.do" target="_blink">
                <img
                  src="../assets/images/home/link-img-03.png"
                  alt="link-logo"
                />
              </a>
            </li>
            <li class="linkList">
              <a
                href="https://www.ulsan.go.kr/u/rep/main.ulsan"
                target="_blink"
              >
                <img
                  src="../assets/images/home/link-img-04.png"
                  alt="link-logo"
                />
              </a>
            </li>
            <li class="linkList">
              <a href="http://www.dangjin.go.kr/kor.do" target="_blink">
                <img
                  src="../assets/images/home/link-img-05.png"
                  alt="link-logo"
                />
              </a>
            </li>
            <li class="linkList">
              <a href="https://innvira.com/en/home/" target="_blink">
                <img
                  src="../assets/images/home/link-img-06.png"
                  alt="link-logo"
                />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import axios from 'axios';
import {REQUEST_URL} from '../serverProtocol.js';
import TheFooter from '../components/TheFooter.vue';
import TheHeader from '../components/TheHeader.vue';

export default {
  components: {TheHeader, TheFooter},
  data() {
    return {
      goalBtnBasic: 'goalBtn',
      goalBtnActive: 'goalBtnActive',
      goalImgBasic: 'goalImgBasic',
      goalImgB2b: 'goalB2b',
      goalImgRenewable: 'goalRenewable',
      goalImgExtend: 'goalExtend',
      goal: 'b2b',
      goalBtnKo: 'goalKo',
      goalBtnEn: 'goalEn',
      styleGoalBtn: {
        display : 'none',
      },
      lang: this.$store.state.lang,
      newsData: {
        total: 0,
        currentPage: 1,
        pageSize: 4,
        data: [],
      },
      topicsData: {
        total: 0,
        currentPage: 1,
        pageSize: 4,
        data: [],
      },
      
    };
  },
  mounted() {
    /**
     * mounted될 때 main화면의 보도자료 섹션에 자료 가져와서 출력하기
     */
    const newsOptions = {
      method: 'GET',
      url: `${REQUEST_URL}api/news`,
      params: {page: '1', pageSize: '4'},
    };

    axios
      .request(newsOptions)
      .then((response) => {
        this.newsData = response.data;
      })
      .catch((error) => {
        console.error(error);
      });

    /**
     * mounted될 때 main화면의 토픽 섹션에 자료 가져와서 출력하기
     */
    const topicsOptions = {
      method: 'GET',
      url: `${REQUEST_URL}api/topics`,
      params: {page: '1', pageSize: '4'}
    };

    axios.request(topicsOptions).then((response) => {
      this.topicsData = response.data;
    }).catch((error) => {
      console.error(error);
    });
  },
  methods: {
    /**
     * news 더보기 버튼 클릭시 news 화면으로 전환
     */
    onClickNewsMoreButton() {
      this.$router.push('/news');
    },
    /**
     * 제품 보러가기 버튼 클릭시 제품 화면으로 전환
     */
    onClickProductsMoreButton() {
      this.$router.push('/products');
    },
    /**
     * 보도자료 더보기 버튼 클릭시 보도자료 화면으로 전환
     */
    onClickTopicMoreButton() {
      this.$router.push('/topic');
    },
    /**
     * 세수의 목표 클릭시 해당하는 리스트 보여줌
     */
    onClickGoalB2b() {
      this.goal = 'b2b';
    },
    onClickGoalRenewable() {
      this.goal = 'renewable';
    },
    onClickGoalExtend() {
      this.goal = 'extend';
    },
  },
  computed: {
    checkLang() {
      return this.$store.state.lang;
    }
  },
  watch: {
    checkLang(value) {
      this.lang = value;
    }
  },
};
</script>

<style lang="scss" scoped>
#homePage {
  .sesuMovie {
    position: relative;

    > img {
      width: 100%;
      height: calc(100vh - 64px);
      object-fit: cover;

      @include respond($t_width) {
        width: 100%;
        height: 73.4949vw;
      }

      @include respond($m_width) {
        width: 100%;
        height: 133.6111vw;
      }
    }

    .saveU {
      position: absolute;
      top: 344px;
      left: 558px;
      font-size: 72px;
      line-height: 98px;
      color: #FFFFFF;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

      @include respond($t_width) {
        top: 140px;
        left: 70px;
        font-size: 60px;
        line-height: 82px;
      }

      @include respond($m_width) {
        top: 120px;
        left: 40px;
        font-size: 36px;
        line-height: 49px;
      }
    }
  }

  #container {
    .sesuMessage {
      width: 1400px;
      height: 240px;
      color: #ffffff;
      font-weight: 700;
      font-size: 36px;
      line-height: 240px;
      text-align: center;
      background-image: url('../assets/images/home/message-bg.png');
      background-size: cover;
      margin: 80px auto 120px;

      @include respond($t_width) {
        width: calc(100% - 68px);
        height: 16.9014vw;
        font-size: 24px;
        line-height: 16.9014vw;
        margin: 50px auto 110px;
      }

      @include respond($m_width) {
        width: calc(100% - 20px);
        margin: 60px auto 99px;
        font-size: 20px;
      }
    }

    #sesuGoal {
      position: relative;
      margin-bottom: 139px;

      @include respond($t_width) {
        margin-bottom: 100px;
      }

      @include respond($m_width) {
        margin-bottom: 70px;
      }

      .sectionTitle {
        position: absolute;
        right: 114px;
        top: 27px;

        @include respond($t_width) {
          position: static;
          margin-bottom: 50px;
        }

        @include respond($m_width) {
          margin-bottom: 40px;
        }

        .subTitle {
          @include subTitle;
          font-size: 20px;

          @include respond($t_width) {
            @include subTitleT;
          }

          .beeIcon {
            @include beeIcon;
            margin-left: 10.5px;

            @include respond($t_width) {
              @include beeIconT;
            }

            @include respond($m_width) {
              @include beeIconM;
            }
          }
        }

        .titleDescription {
          @include titleDescription;
          font-size: 24px;
          line-height: 30px;

          @include respond($t_width) {
            @include titleDescriptionT;
          }

          @include respond($m_width) {
            @include titleDescriptionM;
          }
        }
      }

      .goalKo {
        position: absolute;
        right: 195px;
        top: 192px;
        width: 290px;

        @include respond($t_width) {
          display: none;
        }

        li {
          display: inline-block;
          width: 187px;
          height: 60px;
          color: $mainGray;
          font-weight: 400;
          font-size: 22px;
          line-height: 32px;
          padding: 10px 13px;
          margin: 0 0 14px;
          background-color: #ffffff;
          @include roundButton;
          cursor: pointer;
          transition: all 0.2s;

          .bar {
            width: 60px;
            height: 2px;
            background-color: $mainGray;
            position: absolute;
            left: -20%;
            z-index: -1;
            top: 15%;
          }

          &.goalBtn {
            color: $mainGray;
            font-weight: 400;
            background-color: #ffffff;

            .bar {
              height: 2px;
              background-color: $mainGray;
            }
          }

          &.goalB2b {
            width: 187px;
            .bar {
              top: 15%;
            }
          }

          &.goalRenewable {
            width: 225px;
            .bar {
              top: 50%;
            }
          }

          &.goalExtend {
            width: 167px;
            margin: 0;

            .bar {
              top: 85%;
            }
          }

          &.goalBtnActive {
            color: $mainColor;
            font-weight: 700;
            border: 4px solid $mainColor;

            .bar {
              height: 4px;
              background-color: $mainColor;
            }
          }
        }
      }

      .goalEn {
        position: absolute;
        right: 195px;
        top: 192px;
        width: 290px;

        @include respond($t_width) {
          display: none;
        }

        li {
          display: inline-block;
          width: 187px;
          height: 60px;
          color: $mainGray;
          font-weight: 400;
          font-size: 17px;
          vertical-align: Center;
          line-height: 16px;
          padding: 3px 20px;
          margin: 0 0 14px;
          background-color: #ffffff;
          @include roundButton;
          cursor: pointer;
          transition: all 0.2s;

          .bar {
            width: 60px;
            height: 2px;
            background-color: $mainGray;
            position: absolute;
            left: -20%;
            z-index: -1;
            top: 15%;
          }

          &.goalBtn {
            color: $mainGray;
            font-weight: 400;
            background-color: #ffffff;

            .bar {
              height: 2px;
              background-color: $mainGray;
            }
          }

          &.goalB2b {
            width: 187px;
            padding: 12px 20px;
            .bar {
              top: 15%;
            }
          }

          &.goalRenewable {
            width: 225px;
            .bar {
              top: 50%;
            }
          }

          &.goalExtend {
            width: 167px;
            margin: 0;

            .bar {
              top: 85%;
            }
          }

          &.goalBtnActive {
            color: $mainColor;
            font-weight: 700;
            border: 4px solid $mainColor;

            .bar {
              height: 4px;
              background-color: $mainColor;
            }
          }
        }
      }
      .goalImgWrap {
        position: relative;
        width: 860px;
        height: 400px;

        @include respond($t_width) {
          display: none;
        }

        .goalImg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          padding-left: 60px;
          padding-top: 302px;
          box-sizing: border-box;
          background-repeat: no-repeat;
          transition: opacity 0.3s ease-in-out;

          > p {
            font-weight: 700;
            font-size: 32px;
            color: #ffffff;
            margin-bottom: 16px;
          }

          > span {
            font-weight: 500;
            font-size: 16px;
            color: #dddddd;
          }
        }

        .goalImgBasic {
          opacity: 0;
        }

        .goalB2b {
          background-image: url('../assets/images/home/goal-b2b.png');
        }

        .goalRenewable {
          background-image: url('../assets/images/home/goal-renewable.png');
        }

        .goalExtend {
          background-image: url('../assets/images/home/goal-extend.png');
        }
      }

      .goalImgWrapTab {
        display: none;

        @include respond($t_width) {
          display: block;
          display: flex;
          justify-content: space-between;
        }

        @include respond($m_width) {
          flex-flow: column nowrap;
          align-items: center;
        }

        .goalImg {
          width: 400px;
          height: 33.8028vw;
          margin-right: 10px;
          background-image: url('../assets/images/home/goal-b2b.png');
          background: {
            repeat: no-repeat;
            size: cover;
            position: 50%;
          }
          border-radius: 4px;
          color: #ffffff;
          position: relative;

          @include respond($m_width) {
            width: 88.6571vw;
            height: 52.1512vw;
            margin-right: 0;
            margin-bottom: 12px;
            padding: 20px;
          }

          &.goalRenewable {
            background-image: url('../assets/images/home/goal-renewable.png');
          }

          &.goalExtend {
            background-image: url('../assets/images/home/goal-extend.png');
            margin-right: 0;

            @include respond($m_width) {
              margin-bottom: 0;
            }
          }

          > p {
            position: absolute;
            top: 125px;
            left: 14px;
            font-weight: 700;
            font-size: 16px;
            word-break: keep-all;

            @include respond($m_width) {
              top: 95px;
            }
          }

          > span {
            position: absolute;
            font-size: 14px;
            line-height: 23px;
            top: 185px;
            left: 14px;
            word-break: keep-all;

            @include respond($m_width) {
              font-size: 12px;
              line-height: 16px;
              top: 135px;
            }
          }
        }
      }
    }

    #sesuRe100 {
      position: relative;
      @include borderStyle;
      width: 1400px;
      height: 480px;
      background-color: #ffffff;
      margin-bottom: 140px;

      @include respond($t_width) {
        width: calc(100% - 68px);
        height: 240px;
        margin-bottom: 110px;
      }

      @include respond($m_width) {
        width: calc(100% - 20px);
        height: 347px;
        margin-bottom: 99px;
      }

      .re100 {
        .re100Title {
          font-weight: 700;
          font-size: 64px;
          margin: 67px 0 18px 14px;

          @include respond($t_width) {
            font-size: 48px;
            margin: 20px 0 8px 10px;
          }

          @include respond($m_width) {
            font-size: 32px;
            margin: 38px 0 10px 12px;
          }
        }

        .re100sub {
          display: block;
          font-size: 28px;
          color: $hoverColor;
          margin-left: 17px;

          @include respond($t_width) {
            font-size: 20px;
            margin-left: 10px;
          }

          @include respond($m_width) {
            font-size: 16px;
            margin-left: 15px;
          }
        }

        .re100Bar {
          display: inline-block;
          width: 942px;
          height: 8px;
          background-color: $mainColor;
          margin: 56px 0 40px 20px;

          @include respond($t_width) {
            width: 471px;
            height: 6px;
            margin: 12px 0 16px 10px;
          }

          @include respond($m_width) {
            width: 269px;
            height: 5px;
            margin: 24px 0 42px 15px;
          }
        }

        .re100Text {
          width: 720px;
          font-size: 20px;
          margin-left: 20px;
          color: $newsTitleColor;
          line-height: 35px;
          word-break: keep-all;

          @include respond($t_width) {
            width: 493px;
            font-size: 14px;
            margin-left: 10px;
            line-height: 20px;
          }

          @include respond($m_width) {
            width: 288px;
            font-size: 12px;
            margin-left: 15px;
            line-height: 25px;
          }
        }

        .re100Bg {
          position: absolute;
          width: 417px;
          height: 278px;
          top: 20px;
          right: 0;
          background-image: url('../assets/images/home/re100-bg.png');
          background-repeat: no-repeat;

          @include respond($t_width) {
            width: 208.5px;
            background-size: 208px;
          }

          @include respond($m_width) {
            top: 0;
            background-size: 206px;
            background-image: url('../assets/images/home/re100-m-bg.png');
          }
        }
      }
    }

    #sesuNews {
      .sectionTitle {
        margin-bottom: 78px;

        @include respond($t_width) {
          margin-bottom: 32px;
        }

        @include respond($m_width) {
          margin-bottom: 40px;
        }

        .subTitle {
          @include subTitle;

          @include respond($t_width) {
            @include subTitleT;
          }

          .beeIcon {
            @include beeIcon;

            @include respond($t_width) {
              @include beeIconT;
            }

            @include respond($m_width) {
              @include beeIconM;
            }
          }
        }

        .titleDescription {
          @include titleDescription;

          @include respond($t_width) {
            @include titleDescriptionT;
          }

          @include respond($m_width) {
            @include titleDescriptionM;
          }
        }
      }

      .newsListWrap {
        position: relative;

        @include respond($t_width) {
          margin-bottom: 166px;
        }

        @include respond($m_width) {
          margin-bottom: 146px;
        }

        .newsWrap {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          @include respond($m_width) {
            justify-content: space-around;
          }

          .newsList {
            width: 320px;
            height: 360px;
            padding: 0 20px;
            background-color: #ffffff;
            @include borderStyle;
            cursor: pointer;

            @include respond($t_width) {
              width: 28.6458vw;
              height: 310px;
              padding: 0 10px;

              &:last-child {
                display: none;
              }
            }

            @include respond($m_width) {
              width: 43.3333vw;
              height: 195px;
              padding: 0 12px;
              margin-bottom: 12px;

              &:last-child {
                display: block;
              }
            }

            .newsBar {
              display: block;
              margin: 38px auto 24px;
              @include greenBar(120px, 8px);

              @include respond($t_width) {
                margin: 30px auto 18px;
                @include greenBar(90px, 6px);
              }

              @include respond($m_width) {
                margin: 20px auto 17px;
                @include greenBar(60px, 4px);
              }
            }

            .newsTitle {
              width: 280px;
              height: 55px;
              font-weight: 700;
              font-size: 22px;
              line-height: 26px;
              color: $newsTitleColor;
              margin-bottom: 40px;
              transition: all 0.5s;
              text-overflow: ellipsis;
              overflow: hidden;
              word-wrap: break-word;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              @include respond($t_width) {
                width: 26.0417vw;
                height: 80px;
                font-size: 20px;
                margin-bottom: 14px;
                -webkit-line-clamp: 3;
              }

              @include respond($m_width) {
                width: 36.6667vw;
                height: 40px;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
              }
            }

            .newsText {
              width: 280px;
              height: 128px;
              font-size: 18px;
              color: $newsContentColor;
              line-height: 25px;
              transition: all 0.5s;
              text-overflow: ellipsis;
              overflow: hidden;
              word-wrap: break-word;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;

              @include respond($t_width) {
                width: 26.0417vw;
                height: 110px;
                font-size: 16px;
                line-height: 22px;
              }

              @include respond($m_width) {
                width: 36.6667vw;
                height: 66px;
                font-size: 12px;
                line-height: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 4;
              }
            }

            .newsDate {
              font-size: 16px;
              line-height: 20px;
              color: $newsContentColor;
              margin-top: 10px;
              position: relative;
              padding-left: 26px;
              transition: all 0.5s;

              @include respond($t_width) {
                margin-top: 20px;
              }

              @include respond($m_width) {
                font-size: 12px;
                margin-top: 7px;
                padding-left: 20px;

                img {
                  width: 14.09px;
                  height: auto;
                }
              }

              .clockIcon {
                position: absolute;
                top: 0;
                left: 0;
              }

              .clockHoverIcon {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
              }

              @include respond($m_width) {
                .clockIcon,
                .clockHoverIcon {
                  top: 15%;
                }
              }
            }

            &:hover,
            &:active {
              background-color: transparent;
              background-image: url('../assets/images/home/news-hover-bg.png');
              background-repeat: no-repeat;
              background-size: cover;

              .newsTitle {
                color: #ffffff;
              }

              .newsText {
                color: #ffffff;
              }

              .newsDate {
                color: #ffffff;

                .clockIcon {
                  display: none;
                }

                .clockHoverIcon {
                  display: block;
                }
              }

              @include respond($t_width) {
                background-image: none;
                background-color: #eeeeee;

                .newsTitle {
                  color: $newsTitleColor;
                }

                .newsText {
                  color: $newsContentColor;
                }

                .newsDate {
                  color: $newsContentColor;

                  .clockIcon {
                    display: block;
                  }

                  .clockHoverIcon {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .moreButton {
          width: 180px;
          height: 50px;
          color: $newsContentColor;
          font-weight: 400;
          font-size: 24px;
          background-color: #ffffff;
          border: 1px solid $mainGray;
          border-radius: 30px;
          margin: 30px 0 140px 1219px;
          transition: all 0.2s;

          @include respond($t_width) {
            position: absolute;
            bottom: -64px;
            right: 0;
            width: 160px;
            height: 44px;
            font-size: 20px;
            margin: 0;
          }

          @include respond($m_width) {
            position: absolute;
            bottom: -49px;
            right: 0;
            width: 120px;
            height: 33px;
            font-size: 16px;
            margin: 0;
          }

          img {
            display: inline-block;
            margin-left: 35px;

            @include respond($t_width) {
              width: 9.81px;
              height: auto;
              margin-left: 25px;
            }

            @include respond($m_width) {
              width: 8.83px;
              margin-left: 20px;
            }
          }

          &:hover,
          &:active {
            font-weight: 700;
            background-color: #f6f6f6;

            img {
              width: 13.73px;
              height: 23.33px;
            }

            @include respond($t_width) {
              font-weight: 400;

              img {
                width: 9.81px;
                height: auto;
              }
            }

            @include respond($m_width) {
              img {
                width: 8.83px;
              }
            }
          }
        }
      }
    }

    #sesuProducts {
      .sectionTitle {
        margin-bottom: 70px;

        @include respond($t_width) {
          margin-bottom: 50px;
        }

        @include respond($m_width) {
          margin-bottom: 30px;
        }

        .subTitle {
          @include subTitle;

          @include respond($t_width) {
            @include subTitleT;
          }

          .beeIcon {
            @include beeIcon;

            @include respond($t_width) {
              @include beeIconT;
            }

            @include respond($m_width) {
              @include beeIconM;
            }
          }
        }

        .titleDescription {
          @include titleDescription;

          @include respond($t_width) {
            @include titleDescriptionT;
          }

          @include respond($m_width) {
            @include titleDescriptionM;
          }
        }
      }

      .productsWrap {
        position: relative;
        width: 1400px;
        height: 240px;
        background-image: url('../assets/images/home/products-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        margin: 0 auto 140px;
        border-radius: 4px;

        @include respond($t_width) {
          width: calc(100% - 68px);
          height: 18.2292vw;
          margin: 0 auto 130px;
        }

        @include respond($m_width) {
          width: 100%;
          height: 38.8889vw;
          margin-bottom: 99px;
          border-radius: 0;
          background-position-x: center;
        }

        .productsTitle {
          z-index: 1;
          position: absolute;
          top: 76px;
          left: 50%;
          transform: translate(-50%);
          font-size: 48px;
          color: #ffffff;
          text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.45);
          text-align: center;
          transition: all 0.3s;

          @include respond($t_width) {
            top: 25%;
            font-size: 36px;
          }

          @include respond($m_width) {
            font-size: 32px;
          }
        }

        .productsMore {
          z-index: 1;
          position: absolute;
          top: 160px;
          left: 50%;
          transform: translate(-50%);
          width: 230px;
          height: 40px;
          border: 2px solid #ffffff;
          box-sizing: border-box;

          .gotoProducts {
            width: 100%;
            height: 100%;
            background-color: transparent;
            font-weight: 700;
            font-size: 18px;
            color: #ffffff;
          }

          @include respond($t_width) {
            top: 60%;
            width: 130px;
            height: 30px;

            .gotoProducts {
              font-size: 14px;
              padding: 0;
            }
          }
        }

        .blackBox {
          position: absolute;
          top: 0;
          left: 0;
          width: 1400px;
          height: 240px;
          background: transparent;
          transition: all 0.5s;

          @include respond($t_width) {
            display: none;
          }
        }

        &:hover {
          .productsTitle {
            font-weight: 700;
            font-size: 52px;
          }

          .blackBox {
            background-image: url('../assets/images/home/products-hover-bg.png');
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 4px;
          }

          @include respond($t_width) {
            .productsTitle {
              font-weight: 500;
              font-size: 36px;
            }
          }

          @include respond($m_width) {
            .productsTitle {
              font-weight: 500;
              font-size: 32px;
            }
          }
        }
      }
    }

    #sesuTopic {
      .sectionTitle {
        margin-bottom: 78px;

        @include respond($t_width) {
          margin-bottom: 32px;
        }

        @include respond($m_width) {
          margin-bottom: 40px;
        }

        .subTitle {
          @include subTitle;

          @include respond($t_width) {
            @include subTitleT;
          }

          .beeIcon {
            @include beeIcon;

            @include respond($t_width) {
              @include beeIconT;
            }

            @include respond($m_width) {
              @include beeIconM;
            }
          }
        }

        .titleDescription {
          @include titleDescription;

          @include respond($t_width) {
            @include titleDescriptionT;
          }

          @include respond($m_width) {
            @include titleDescriptionM;
          }
        }
      }

      .topicListWrap {
        position: relative;

        @include respond($t_width) {
          margin-bottom: 144px;
        }

        @include respond($m_width) {
          margin-bottom: 140px;
        }

        .topicWrap {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          @include respond($m_width) {
            justify-content: space-around;
          }

          .topicList {
            width: 320px;
            height: 206px;
            padding: 0 20px;
            background-color: #ffffff;
            @include borderStyle;
            cursor: pointer;
            transition: all linear 0.2s;

            @include respond($t_width) {
              width: 28.6458vw;
              height: 184px;
              padding: 0 10px;

              &:last-child {
                display: none;
              }
            }

            @include respond($m_width) {
              width: 43.3333vw;
              height: 128px;
              padding: 0 12px;
              margin-bottom: 8px;

              &:last-child {
                display: block;
              }
            }

            .newsBar {
              display: block;
              margin: 38px auto 24px;
              @include greenBar(120px, 8px);

              @include respond($t_width) {
                margin: 30px auto 18px;
                @include greenBar(90px, 6px);
              }

              @include respond($m_width) {
                margin: 22px auto 17px;
                @include greenBar(60px, 4px);
              }
            }

            .topicTitle {
              width: 280px;
              height: 52px;
              font-weight: 700;
              font-size: 22px;
              line-height: 26px;
              color: $newsTitleColor;
              margin-bottom: 24px;
              text-overflow: ellipsis;
              overflow: hidden;
              word-wrap: break-word;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              @include respond($t_width) {
                width: 26.0417vw;
                height: 75px;
                font-size: 20px;
                margin-bottom: 20px;
                -webkit-line-clamp: 3;
              }

              @include respond($m_width) {
                width: 36.6667vw;
                height: 40px;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
              }
            }

            .topicDate {
              font-size: 16px;
              line-height: 19px;
              color: $newsContentColor;
              position: relative;
              padding-left: 26px;

              .clockIcon {
                position: absolute;
                top: 0;
                left: 0;
              }

              @include respond($m_width) {
                font-size: 12px;
                margin-top: 0;
                padding-left: 20px;

                img {
                  width: 13.26px;
                  height: auto;
                }

                .clockIcon {
                  top: 17%;
                }
              }
            }

            &:hover,
            &:active {
              background-color: #f7f7f7;
              box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);

              @include respond($t_width) {
                background-color: #eeeeee;
                box-shadow: none;
              }
            }
          }
        }

        .moreButton {
          width: 180px;
          height: 50px;
          color: $newsContentColor;
          font-weight: 400;
          font-size: 24px;
          background-color: #ffffff;
          border: 1px solid $mainGray;
          border-radius: 30px;
          margin: 30px 0 140px 1219px;
          transition: all 0.2s;

          @include respond($t_width) {
            position: absolute;
            bottom: -64px;
            right: 0;
            width: 160px;
            height: 44px;
            font-size: 20px;
            margin: 0;
          }

          @include respond($m_width) {
            position: absolute;
            bottom: -49px;
            right: 0;
            width: 120px;
            height: 33px;
            font-size: 16px;
            margin: 0;
          }

          img {
            display: inline-block;
            margin-left: 35px;

            @include respond($t_width) {
              width: 9.81px;
              height: auto;
              margin-left: 25px;
            }

            @include respond($m_width) {
              width: 8.83px;
              margin-left: 20px;
            }
          }

          &:hover,
          &:active {
            font-weight: 700;
            background-color: #f6f6f6;

            img {
              width: 13.73px;
              height: 23.33px;
            }

            @include respond($t_width) {
              font-weight: 400;

              img {
                width: 9.81px;
                height: auto;
              }
            }

            @include respond($m_width) {
              img {
                width: 8.83px;
              }
            }
          }
        }
      }
    }

    #sesuLink {
      margin-bottom: 120px;

      @include respond($t_width) {
        margin-bottom: 100px;
      }

      @include respond($m_width) {
        margin-bottom: 68px;
      }

      .sectionTitle {
        margin-bottom: 74px;

        @include respond($t_width) {
          margin-bottom: 20px;
        }

        @include respond($m_width) {
          margin-bottom: 40px;
        }

        .subTitle {
          @include subTitle;

          @include respond($t_width) {
            @include subTitleT;
          }

          .beeIcon {
            @include beeIcon;

            @include respond($t_width) {
              @include beeIconT;
            }

            @include respond($m_width) {
              @include beeIconM;
            }
          }
        }
      }

      .linkListWrap {
        .linkWrap {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .linkList {
            width: 220px;
            height: 80px;
            padding: 17px 41px 0;
            background-color: #ffffff;
            @include borderStyle;
            cursor: pointer;
            transition: all linear 0.2s;
            margin-bottom: 14px;

            @include respond($t_width) {
              width: 200px;
              height: 73px;
              padding: 14px 31px 0;
              margin-bottom: 14px;
            }

            @include respond($m_width) {
              width: 151px;
              height: 54px;
              padding: 4px 6px 0;
              margin-bottom: 8px;
            }

            &:hover,
            &:active {
              box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);

              @include respond($t_width) {
                box-shadow: none;
                background-color: #eeeeee;
              }
            }
          }
        }
      }
    }
  }
}
</style>
