<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@font-face {
    font-family: 'Hoengseong-Hanu';
    src:url('./assets/font/Hoengseong-Hanu.otf') format('woff');
    font-weight: 400;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
strong,
b,
u,
i,
center,
ol,
ul,
li,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  vertical-align: baseline;
  box-sizing: border-box;
  line-height: 100%;
}

ol,
ul {
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
  outline: none;
}

a {
  color: #000000;
  text-decoration: none;
  outline: none;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  background-image: url('./assets/images/pc-bg.png');
  overflow-y: scroll;
}

.center {
  width: 1400px;
  margin: 0 auto;

  @include respond($t_width) {
    width: calc(100% - 68px); 
  }

  @include respond($m_width) {
    width: calc(100% - 20px);
  }
}
</style>
